import React from "react";
import Formation from "../Components/Formation";
import Experience from "../Components/Experience";
import CompetencesImages from "../Components/CompetencesImages";

export default function AboutMe() {
    return <div className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
        <h2 className="text-4xl font-semibold mb-4">A propos de moi</h2>
        <div className="bg-gray-200 rounded-xl px-6 py-3 mb-6">
            <p className="mb-4">Bienvenue à tous,</p>
            <p className="mb-4">Je suis Téo Fiminski, un passionné d'informatique âgé de 20 ans (né le 31.07.2003) et
                étudiant en BTS Services Informatiques aux Organisations (SIO), spécialisé en Solutions Logicielles et
                Applications Métiers (SLAM).</p>
            <p className="mb-4">Mon voyage dans le monde du développement a débuté au collège, où j'ai découvert ma passion
                pour l'informatique. J'ai commencé à coder en LUA pour des serveurs de jeux vidéo, puis j'ai progressivement
                élargi mes compétences au développement web et mobile. Ces premières expériences m'ont poussé à poursuivre
                mes études dans ce domaine.</p>
            <p className="mb-4">Au fil des années, mes études m'ont permis de consolider mes compétences. Aujourd'hui, je me
                sens capable de jouer un rôle moteur au sein d'une équipe de développement et de gérer efficacement des
                projets informatiques.</p>
            <p className="mb-4">Actuellement, je me concentre sur l'amélioration continue de mes compétences en
                développement logiciel. Je suis à la recherche d'opportunités d'alternance pour l'année prochaine, afin de
                poursuivre mes études tout en acquérant une expérience concrète dans le monde professionnel.</p>

            <p className="mb-4">N'hésitez pas à explorer mes réalisations présentées ici. Je serais ravi de discuter de
                collaborations ou d'opportunités professionnelles avec vous.</p>

            <p className="">Merci de votre visite et à bientôt !</p>
        </div>
        <div className="flex flex-wrap bg-gray-200 rounded-xl px-6 py-3 mb-6">
            <Formation/>
            <Experience/>
        </div>
        <div className="flex flex-wrap bg-gray-200 rounded-xl px-6 py-3 mb-6">
            <CompetencesImages/>
        </div>
    </div>
}