import React from "react";
import {
    Navbar,
    Typography,
    IconButton, Collapse,
} from "@material-tailwind/react";
import {Link} from "react-router-dom";

export function Header() {
    const [openNav, setOpenNav] = React.useState(false);

    React.useEffect(() => {
        window.addEventListener(
            "resize",
            () => window.innerWidth >= 960 && setOpenNav(false),
        );
    }, []);

    const closeNav = () => {
        setOpenNav(false);
    };

    const navList = <ul className="mt-2 mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="flex items-center gap-x-2 p-1 font-medium"
        >
            <Link to={"/"} className="flex items-center" onClick={closeNav}>
                Accueil
            </Link>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="flex items-center gap-x-2 p-1 font-medium"
        >
            <Link to={"/aboutme"} className="flex items-center" onClick={closeNav}>
                A propos de moi
            </Link>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="flex items-center gap-x-2 p-1 font-medium"
        >
            <Link to={"/competences"} className="flex items-center" onClick={closeNav}>
                Competences
            </Link>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="flex items-center gap-x-2 p-1 font-medium"
        >
            <Link to={"/projets"} className="flex items-center" onClick={closeNav}>
                Projets
            </Link>
        </Typography>
        <Typography
            as="li"
            variant="small"
            color="blue-gray"
            className="flex items-center gap-x-2 p-1 font-medium"
        >
            <Link to={"/veille_techno"} className="flex items-center" onClick={closeNav}>
                Veille Techno
            </Link>
        </Typography>
    </ul>

    return (
        <Navbar className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
            <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
                <Typography
                    className="mr-4 cursor-pointer py-1.5 font-medium"
                >
                    <Link to={"/"} onClick={closeNav}>
                        Fiminski Téo Portfolio
                    </Link>
                </Typography>
                <div className="hidden lg:block">{navList}</div>

                <IconButton
                    variant="text"
                    className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
                    ripple={false}
                    onClick={() => setOpenNav(!openNav)}
                >
                    {openNav ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            className="h-6 w-6"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M4 6h16M4 12h16M4 18h16"
                            />
                        </svg>
                    )}
                </IconButton>
            </div>
            <Collapse open={openNav}>
                <div className={"container mx-auto"}>
                    {openNav ? navList : <></>}
                </div>
            </Collapse>
        </Navbar>
    );
}