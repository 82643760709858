import React from "react";

export default function VeilleTechnologique() {
    return <div className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
        <h3 className="text-2xl mb-6 font-bold">Maintenir à jour les langages de programmation pour la sécurité des
            projets.</h3>
        <h4 className="text-xl mb-2 font-semibold">Introduction</h4>
        <p className="text-md mb-6">La mise à jour régulière des versions des langages est primordiale pour garantir la
            sécurité des projets informatiques. En effet, les langages de programmation, tout comme les logiciels, sont
            sujets à des failles et vulnérabilités qui peuvent être exploitées par des acteurs malveillants pour
            compromettre les systèmes et les données.</p>
        <h4 className="text-xl mb-2 font-semibold">Les différents stades de vie d'une version de langage</h4>
        <p className="text-md mb-2">Les langages de programmation évoluent en permanence. Chaque version majeure apporte
            de nouvelles fonctionnalités, corrige des bugs et améliore les performances. Cependant, il est important de
            comprendre que chaque version a un cycle de vie défini par ses développeurs.</p>
        <ul className={"list-disc ml-6 mb-6"}>
            <li>
                <span className={"text-md font-semibold"}>Support actif / Active Support :</span> C'est la phase pendant
                laquelle les développeurs du langage fournissent des mises à jour correctives et de sécurité régulières.
                C'est la période la plus sûre pour utiliser une version de langage.
            </li>
            <li>
                <span
                    className={"text-md font-semibold"}>Corrections de sécurité uniquement / Security Fixes Only :</span> Dans
                cette phase, les développeurs ne fournissent plus de nouvelles fonctionnalités, mais continuent à
                publier des correctifs pour les failles de sécurité critiques. L'utilisation de versions dans cette
                phase est encore acceptable, mais il est important de mettre à jour rapidement dès qu'un correctif est
                disponible.
            </li>
            <li>
                <span className={"font-semibold"}>Fin de vie / End of Life :</span> Une fois qu'une version atteint
                sa fin de vie, elle ne reçoit plus aucune mise à jour, ni corrective ni de sécurité. L'utilisation de
                versions en fin de vie est fortement déconseillée car elles représentent un risque important pour la
                sécurité des projets.
            </li>
        </ul>
        <h4 className="text-xl mb-2 font-semibold">Exemple avec PHP et Symfony</h4>
        <p className="text-md mb-2">Prenons l'exemple du langage de programmation PHP et de son framework Symfony. La
            version PHP 8.3 est actuellement en phase de support actif et bénéficie de mises à jour régulières. La
            version PHP 8.1 est en phase de "Corrections de sécurité uniquement" jusqu'au 8 décembre 2024, après quoi
            elle atteindra sa fin de vie. Il est donc important de mettre à jour tous les projets PHP 8.1 vers PHP 8.3
            avant cette date.</p>
        <img src={"https://assets.portfolio.tfia.fr/img/php_versions.png"} alt={"Versions de PHP"}
             className={"w-full mb-4"}/>
        <p className="text-md mb-2">Symfony fonctionne plus ou moins de la même manière, mais avec une fonction de «
            Long term Support » en addition.
            La version « Long Term Support » peut être une version plus ancienne, mais plus stable et maintenue pendant
            une période de temps certaine.
            La version 5.4, correspondant à la version 7 de php sera maintenue en « Active Support » jusqu’à fin 2024
            puis en « Security fixes only » jusqu’à fin 2025.
            La version 6.4, qui est actuellement la version « Long Term Support » la plus récente sera maintenue en «
            Active Support » jusqu’à fin 2027 puis en « Security fixes only » jusqu’à fin 2028. On remarque que ces
            dates se collent sur les versions de PHP.</p>
        <img src={"https://assets.portfolio.tfia.fr/img/symfony_versions.png"} alt={"Versions de PHP"}
             className={"w-full md:w-2/3 xl:w-1/2 mb-6"}/>
        <h4 className="text-xl mb-2 font-semibold">Les conséquences de ne pas mettre à jour les versions des
            langages</h4>
        <p className="text-md mb-2">Ne pas mettre à jour les versions des langages de programmation peut avoir des
            conséquences graves pour la sécurité des projets, notamment :</p>
        <ul className={"list-disc ml-6 mb-6 text-md"}>
            <li>
                <p><span className={"font-semibold"}>Exposition aux failles de sécurité:</span> Les failles de sécurité
                    connues ne sont corrigées que dans les nouvelles versions des langages. Continuer à utiliser une
                    version obsolète expose les projets aux attaques qui exploitent ces failles.</p>
                <p>Un exemple est disponible dans la version avant PHP 7.3 :</p>
            </li>
            <li className={"ml-6"}>
                <p>CVE-2019-9637 :</p>
                <p>Un problème a été découvert dans PHP avant 7.1.27, 7.2.x avant 7.2.16, et 7.3.x avant 7.3.3. A cause
                    de la façon dont rename() est implémenté dans les systèmes de fichiers, il est possible que le
                    fichier renommé soit brièvement disponible avec de mauvaises permissions pendant que le renommage
                    est en cours, permettant ainsi à des utilisateurs non autorisés d'accéder aux données.</p>
            </li>
            <li>
                <p><span className={"font-semibold"}>Incompatibilités avec les bibliothèques et frameworks:</span> Les
                    nouvelles versions des langages introduisent souvent des changements qui peuvent rendre les
                    bibliothèques et frameworks obsolètes incompatibles. Cela peut entraîner des bugs, des
                    dysfonctionnements et des problèmes de sécurité.</p>
            </li>
            <li>
                <p><span className={"font-semibold"}>Diminution des performances :</span> Les nouvelles versions des
                    langages apportent souvent des améliorations des performances. Continuer à utiliser une version
                    obsolète peut ralentir les applications et nuire à l'expérience utilisateur.</p>
            </li>
        </ul>
        <h4 className="text-xl mb-2 font-semibold">Conclusion</h4>
        <p className="text-md mb-6">Maintenir à jour les versions des langages de programmation est une mesure de
            sécurité essentielle pour tous les projets informatiques. En utilisant les versions les plus récentes, les
            développeurs peuvent s'assurer que leurs projets bénéficient des dernières fonctionnalités, corrections de
            bugs et protections de sécurité. Il est important de se tenir informé des cycles de vie des langages
            utilisés et de mettre à jour les projets dès que possible vers de nouvelles versions.</p>
        <h4 className="text-xl mb-2 font-semibold">Sources</h4>
        <ul className={"text-md"}>
            <li>Source 1 : <a href={"https://symfony.com/releases"} className={"underline"} target={"_blank"} rel={"noreferrer"}>https://symfony.com/releases</a></li>
            <li>Source 2 : <a href={"https://www.php.net/supported-versions.php"} className={"underline"} target={"_blank"} rel={"noreferrer"}>https://www.php.net/supported-versions.php</a></li>
            <li>Source 3 : <a href={"https://www.cert.ssi.gouv.fr/information/CERTA-2007-INF-002/"} className={"underline"} target={"_blank"} rel={"noreferrer"}>https://www.cert.ssi.gouv.fr/information/CERTA-2007-INF-002/</a></li>
            <li>Source 4 : <a href={"https://www.cvedetails.com/cve/CVE-2019-9637/"} className={"underline"} target={"_blank"} rel={"noreferrer"}>https://www.cvedetails.com/cve/CVE-2019-9637/</a></li>
        </ul>
    </div>
}