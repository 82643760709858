import React from "react";
import {Link} from "react-router-dom";

export default function Footer() {
    return <footer className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
        <div className="flex flex-wrap">
            <div className="w-full md:w-1/2 md:flex md:justify-center mb-6 md:mb-0">
                <div className="mx-auto">
                    <h3 className="text-lg font-semibold mb-2">Contact</h3>
                    <p className="text-sm">Adresse: 59000, Lille</p>
                    <p className="text-sm">Téléphone: 0760530460</p>
                    <p className="text-sm">Email: teo@tfia.fr</p>
                </div>
            </div>
            <div className="w-full md:w-1/2 md:flex md:justify-center">
                <div className="mx-auto">
                    <h3 className="text-lg font-semibold mb-2">Navigation</h3>
                    <Link to={"/"} className="text-sm "><p> Accueil </p></Link>
                    <Link to={"/aboutme"} className="text-sm "><p> A propos de moi </p></Link>
                    <Link to={"/competences"} className="text-sm "><p> Competences </p></Link>
                    <Link to={"/projets"} className="text-sm "><p> Projets </p></Link>
                    <Link to={"/veille_techno"} className="text-sm"><p> Veille Techno </p></Link>
                </div>
            </div>
        </div>
    </footer>
}