import React,{useState, useEffect} from "react";
import ProjectSmallCard from "../Components/ProjectSmallCard";

export default function Projets() {
    const [projetscours1, setProjetscours1] = useState([]);
    const [projetscours2, setProjetscours2] = useState([]);
    const [projetsentreprise1, setProjetsentreprise1] = useState([]);
    const [projetsentreprise2, setProjetsentreprise2] = useState([]);

    useEffect(() => {
        fetch("https://api.portfolio.tfia.fr/v2/projets/context/rcf1")
            .then(response => response.json())
            .then(data => setProjetscours1(data));

        fetch("https://api.portfolio.tfia.fr/v2/projets/context/rcf2")
            .then(response => response.json())
            .then(data => setProjetscours2(data));

        fetch("https://api.portfolio.tfia.fr/v2/projets/context/ree1")
            .then(response => response.json())
            .then(data => setProjetsentreprise1(data));

        fetch("https://api.portfolio.tfia.fr/v2/projets/context/ree2")
            .then(response => response.json())
            .then(data => setProjetsentreprise2(data));
    }, []);

    return <div className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
        <h3 className="text-2xl mb-6">Projets réalisés en 1ère Année (en cours) : </h3>
        <div className="grid grid-cols-2 gap-6 md:grid-cols-4 mb-6">
            {
                projetscours1.map(projet => <ProjectSmallCard key={projet.id} project={projet}/>)
            }
        </div>
        <h3 className="text-2xl mb-6">Projets réalisés en 2nde Année (en cours) : </h3>
        <div className="grid grid-cols-2 gap-6 md:grid-cols-4 mb-6">
            {
                projetscours2.map(projet => <ProjectSmallCard key={projet.id} project={projet}/>)
            }
        </div>
        <h3 className="text-2xl mb-6">Projets réalisés en 1ère Année (en entreprise) : </h3>
        <div className="grid grid-cols-2 gap-6 md:grid-cols-4 mb-6">
            {
                projetsentreprise1.map(projet => <ProjectSmallCard key={projet.id} project={projet}/>)
            }
        </div>
        <h3 className="text-2xl mb-6">Projets réalisés en 2nde Année (en entreprise) : </h3>
        <div className="grid grid-cols-2 gap-6 md:grid-cols-4 mb-6">
            {
                projetsentreprise2.map(projet => <ProjectSmallCard key={projet.id} project={projet}/>)
            }
        </div>
    </div>
}