import React, {useEffect, useState} from "react";

export default function Experience() {
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        fetch("https://api.portfolio.tfia.fr/v2/experiences")
            .then(response => response.json())
            .then(data => setExperiences(data));
    }, []);

    return <div className="w-full lg:w-1/2">
        <h3 className="text-2xl font-semibold mb-4 underline">Experiences Professionnelles</h3>
        {experiences.map(experience => <div className="mb-4" key={experience.id}>
            <h4 className="text-lg font-semibold">{experience.entreprise} - {experience.poste}</h4>
            <p className="text-gray-600">📌 {experience.lieu}</p>
            <p className="text-gray-600">📆 {experience.debut} - {experience.fin}</p>
            <p>🗒️ Description :</p>
            <ul className="list-disc">
                {experience.description.map((description, index) =>
                    <li key={index} className="ml-8">
                        <p>{description}</p>
                    </li>
                )}
            </ul>
        </div>)}
    </div>
}