import React, {useState, useEffect} from "react";

export default function CompetencesImages() {
    const [competences, setCompetences] = useState([]);

    useEffect(() => {
        fetch("https://api.portfolio.tfia.fr/v2/competences/images")
            .then(response => response.json())
            .then(data => setCompetences(data));
    }, []);

    return <div className="w-full">
        <h3 className="text-2xl font-semibold mb-4">Compétences</h3>
        <div className="grid gap-6 grid-cols-4 md:grid-cols-6 lg:grid-cols-8 xl:grid-cols-10">
            {competences.map(competence => <div className="mb-4" key={competence.id}>
                <img className="aspect-square" src={competence.link} alt={competence.libelle} />
                <p className="text-center w-full mt-1">{competence.libelle}</p>
            </div>)}
        </div>
    </div>
}