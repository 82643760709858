import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

export default function Competences() {
    const [competences, setCompetences] = useState([]);

    useEffect(() => {
        fetch("https://api.portfolio.tfia.fr/v2/competences")
            .then(response => response.json())
            .then(data => setCompetences(data));
    }, []);

    return <div className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
        <h2 className="text-4xl font-semibold mb-4">Projets correspondant aux compétences BTS</h2>
        {competences.map(competence =>
            <div className="bg-gray-200 rounded-xl px-6 py-3 mb-6" key={competence.id}>
                <h3 className="text-2xl font-semibold mb-4 underline">{competence.libelle}</h3>
                <ul className="list-disc">
                    {competence.justifications.map(projet =>
                        <li className='ml-6 hover:underline'>
                            <Link to={'/projet/' + projet.idProjet}>
                                <p>{projet.projet}</p>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        )}
    </div>
}