import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom"
import {Header} from "./Components/header";
import Home from "./Pages/Home";
import AboutMe from "./Pages/AboutMe";
import Competences from "./Pages/Competences";
import VeilleTechnologique from "./Pages/VeilleTechnologique";
import Footer from "./Components/Footer";
import Projets from "./Pages/Projets";
import Projet from "./Pages/Projet";

function App() {
    return (
        <BrowserRouter>
            <Header/>
            <Routes>
                <Route path="/" exact Component={Home}/>
                <Route path="/aboutme" exact Component={AboutMe}/>
                <Route path="/competences" exact Component={Competences}/>
                <Route path="/projets" exact Component={Projets}/>
                <Route path="/projet/:id" exact Component={Projet}/>
                <Route path="/veille_techno" exact Component={VeilleTechnologique}/>
                <Route path="*" exact element={<h1>404 not found</h1>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
