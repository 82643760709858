import React, {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';



export default function Projet() {
    const {id} = useParams();
    const [projet, setProjet] = useState(null);

    useEffect(() => {
        fetch("https://api.portfolio.tfia.fr/v2/projets/" + id)
            .then(response => response.json())
            .then(data => setProjet(data));
    }, [id]);

    return <div className="mx-auto max-w-screen-xl px-4 py-2 lg:px-8 lg:py-4 my-2 bg-gray-100 rounded-xl">
        {
            projet ? <>
                <h3 className="text-2xl mb-6">{projet.libelle}</h3>
                <p className="text-lg mb-6">{projet.description}</p>
                {projet.justifications.map((justification) => {
                    return <div key={justification.id}>
                        <h4 className="text-xl font-semibold mb-2 mt-4">{justification.competence}</h4>
                        {justification.preuves.filter((preuve) => preuve.type === "IMG").length >0 ?
                            <Splide aria-label="My Favorite Images" className={"w-full md:w-2/3 xl:w:1/2"}>
                            {justification.preuves.map((preuve) => {
                                if(preuve.type === "IMG") {
                                    return <SplideSlide key={preuve.id}>
                                        <img src={preuve.content} alt={preuve.id}/>
                                    </SplideSlide>
                                } else {
                                    return <></>;
                                }
                            })}
                            </Splide>
                        : <></>}
                        {justification.preuves.map((preuve) => {
                            if(preuve.type === "TEXT") {
                                return <p>{preuve.content}</p>
                            } else {
                                return <></>;
                            }
                        })}
                    </div>
                })}</>
        : <p>Chargement...</p>
}
    </div>
}