import React, {useEffect, useState} from "react";

export default function Formation() {
    const [formations, setFormations] = useState([]);

    useEffect(() => {
        fetch("https://api.portfolio.tfia.fr/v2/formations")
            .then(response => response.json())
            .then(data => setFormations(data));
    }, []);

    return <div className="w-full lg:w-1/2">
        <h3 className="text-2xl font-semibold mb-4 underline">Formations</h3>
        {formations.map(formation => <div className="mb-4" key={formation.id}>
            <h4 className="text-lg font-semibold">{formation.etablissement} - {formation.diplome} ({formation.domaine})</h4>
            <p className="text-gray-600">📌 {formation.ville}</p>
            <p className="text-gray-600">📆 {formation.debut} - {formation.fin}</p>
            <p>🗒️ {formation.description}</p>
        </div>)}
    </div>

}